<template>
  <el-row style="overflow-y: auto;">
    <div v-loading="loading.listLoading"></div>
    <el-empty v-if="headerList.length === 0 && !loading.listLoading" class="py-80" description="暂无数据"></el-empty>
    <el-col
        v-else
        :span="24"
        v-for="head in headerList"
        style="border-bottom: 1px solid #eee;position: relative;"
    >
      <el-descriptions class="px-30 pt-20 pb-10 cp" @click="checkHeader(head)">
        <template slot="title">
          <i v-if="head.type==='0'" class="el-icon-s-flag tc-warning"></i>
          <i v-else class="el-icon-s-custom tc-third"></i>
          <span class="ml-8 mr-20">{{ head.header }}</span>
        </template>
        <template slot="extra">
          <el-button v-if="$route.query.from==='invoice'" size="mini" type="warning" @click="handleOpen(head)">选择此抬头去开票</el-button>
          <div v-else>
            <el-button size="mini" type="primary" icon="el-icon-edit" circle @click="handleEdit(head)"></el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="handleDel(head)"></el-button>
          </div>
        </template>
        <el-descriptions-item v-if="head.type==='0'" label="税号">{{ head.taxid }}</el-descriptions-item>
        <el-descriptions-item v-if="head.type==='0'" label="地址">{{ head.address }}</el-descriptions-item>
        <el-descriptions-item v-if="head.type==='0'" label="电话号码">{{ head.telephone }}</el-descriptions-item>
        <el-descriptions-item v-if="head.type==='0'" label="开户银行">{{ head.bank }}</el-descriptions-item>
        <el-descriptions-item v-if="head.type==='0'" label="银行账号">{{ head.bankaccount }}</el-descriptions-item>
      </el-descriptions>
    </el-col>
  </el-row>
</template>

<script>
import publicFun from "@/mixins/public";
import interfaceWechat from "@/mixins/interfaceWechat";

export default {
  name: "headerInfo",
  mixins: [publicFun, interfaceWechat],
  data () {
    return {
      fromPage: '',
      searchParams: {
        unionid: ''
      },
      loading: {
        listLoading: false
      },
      headerList: []
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromPage = from.params.type
    });
  },
  mounted() {
    this.searchParams.unionid = sessionStorage.userinfo ? JSON.parse(sessionStorage.userinfo).unionid : '';
    if (this.searchParams.unionid) this.getList();
  },
  methods: {
    getList() {
      this.getInfo('/billing-information/list', this.searchParams, 'headerList');
    },
    handleOpen(item) {
      if (item === 'noparams') this.fromPage = 'header';
      if (this.goLogin()) {
        this.$router.push({
          name: 'invoice',
          params: {
            type: this.fromPage || 'invoice',
            item: JSON.stringify(item)
          }
        });
      }
    },
    handleEdit(item) {
      this.$router.push({
        name: 'invoice',
        params: {
          type: 'headerEdit',
          item: JSON.stringify(item)
        }
      });
    },
    handleDel(item) {
      let params = {
        unionid: item.unionid,
        type: item.type,
        taxid: item.taxid
      }
      this.deleteInfo('/billing-information/delete', params, this.getList);
    },
    checkHeader(item) {
      item.checked = !item.checked;
    }
  }
}
</script>

<style scoped lang="scss">
.el-descriptions {
  &:hover {
    background: #f6f7ff;
  }
  ::v-deep {
    .el-descriptions__body {
      background: transparent;
    }
    .el-descriptions__table {
      td {
        padding: 0 10px 12px;
      }
    }
  }
}
.bgChecked {
  background: #f6f7ff;
}
</style>